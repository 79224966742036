import React, { useEffect } from 'react'
import { Cancel01Icon } from '@hugeicons/react'
import { Link } from '@inertiajs/inertia-react'

interface MobileMenuProps {
  isOpen: boolean
  onClose: () => void
}

export default function MobileMenu({ isOpen, onClose }: MobileMenuProps) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : ''

    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  return (
    <section
      className={`fixed inset-0 bg-gray-700 bg-opacity-30 z-[1000] md:hidden transition-opacity duration-500 ease-in-out ${
        isOpen
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      }`}
    >
      <div className="flex flex-col items-end justify-end h-full px-5">
        <div
          className={`min-h-[23rem] bg-white w-full rounded-[1rem] py-12 px-8 transform transition-transform duration-500 ease-in-out ${
            isOpen ? 'translate-y-0' : 'translate-y-full'
          }`}
        >
          <div className="flex justify-end">
            <Cancel01Icon
              size={20}
              color={'#EF4444'}
              variant={'twotone'}
              onClick={onClose}
            />
          </div>
          <div className="space-y-3 text-[1.3rem] text-textGray mt-4">
            <Link className="block" href="/cars" onClick={onClose}>
              Buy a Car
            </Link>
            <Link className="block" href="/sell" onClick={onClose}>
              Sell a Car
            </Link>
            {/*   <Link className="block" href="/inspection" onClick={onClose}>
              Get Inspection
            </Link> */}
            <Link className="block" href="peach_process" onClick={onClose}>
              Peach Processes
            </Link>
            <Link className="block" href="/resources" onClick={onClose}>
              Resources
            </Link>
            <Link className="block" href="/about" onClick={onClose}>
              About Us
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
