import { Link } from '@inertiajs/inertia-react'
import Container from './Container'
import { CheckmarkBadge01Icon, CallIcon, UserIcon } from '@hugeicons/react'
import Rectangle from '../shared/Rectangle'

export default function TopNav() {
  return (
    <Container size="centered" className=" !bg-grayLight font-normal ">
      <section
        aria-label="Top secondary navigation bar"
        className="flex flex-row items-center justify-center md:justify-between h-[3.5rem] "
      >
        <div className="flex-row items-center justify-end hidden h-full gap-4 md:flex">
          <div>
            <CheckmarkBadge01Icon
              size={13.33}
              color={'#374151'}
              variant={'stroke'}
            />
          </div>

          <p className="text-[1.1rem]  text-textGray  ">
            Peach cars goes through levels of screening & inspection to protect
            our customers and match their quality expectations.
          </p>
        </div>

        <div className="flex justify-start gap-4 text-textGray">
          <div className="inline-flex items-center gap-3 group ">
            <CallIcon
              className="transition ease-in-out group-hover:text-pinkDark "
              size={16}
              color={'#374151'}
              variant={'twotone'}
            />
            <p className="text-[1.3rem] group-hover:text-pinkDark transition ease-in-out ">
              Call:{' '}
              <span>
                <a href="tel:+254709726900">0709 726900</a>
              </span>
            </p>
          </div>
          <Rectangle className="bg-darkBlue" />
          <div className="inline-flex items-center gap-3 group">
            <UserIcon
              className="transition ease-in-out group-hover:text-pinkDark"
              size={16}
              color={'#374151'}
              variant={'twotone'}
            />
            <Link
              className="block text-[1.3rem] group-hover:text-pinkDark transition ease-in-out "
              href="/sign-in"
            >
              Sign in
            </Link>
          </div>
        </div>
      </section>
    </Container>
  )
}
