import { ButtonProps } from '../../types/ui'

export default function Button({
  type = 'submit',
  className = '',
  processing = false,
  children,
  onClick,
  disabled = false,
  title,
}: ButtonProps) {
  return (
    <button
      title={title}
      type={type}
      onClick={onClick}
      className={
        `inline-flex items-center justify-center px-6 py-2 bg-pinkDark rounded-[1rem]  text-[1.3rem] text-white active:bg-teal-500 transition ease-in-out duration-150 ${
          processing ? 'opacity-25' : ''
        } ` + className
      }
      disabled={disabled || processing}
    >
      {children}
    </button>
  )
}
