import React from 'react'
import { createRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/inertia-react'
import { InertiaProgress } from '@inertiajs/progress'
import axios from 'axios'
import Layout from '../layouts/Layout'
import { PageComponent, Pages } from '../types/layout'

// Import pages dynamically
const pages = import.meta.glob('../pages/**/*.tsx') as Pages

document.addEventListener('DOMContentLoaded', () => {
  const metaTag = document.querySelector('meta[name="csrf-token"]')
  const csrfToken = metaTag ? metaTag.getAttribute('content') || '' : ''
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  InertiaProgress.init({ color: '#000' })

  createInertiaApp({
    title: title => `${title} - Peach Cars`,
    resolve: async (name: string) => {
      const pageModule = pages[`../pages/${name}.tsx`]
      if (!pageModule) {
        throw new Error(`Page not found: ${name}`)
      }

      const page = (await pageModule()).default as PageComponent
      page.layout = page.layout || Layout
      return page
    },
    setup({ el, App, props }) {
      const root = createRoot(el)
      root.render(<App {...props} />)
    },
  })
})
